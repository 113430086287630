<template>

  <!-- navbar-section -->
    <nav
      class="navbar navbar-expand-lg navbar-light" 
    >
      <div class="container-md pt-md-2 pt-2">
        <a class="navbar-brand" href="/">
          <img src="@/assets/Works.png" alt="logo img-fluid" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasDarkNavbar"
          aria-controls="offcanvasDarkNavbar"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="sidebar offcanvas offcanvas-start text-bg-dark"
          tabindex="-1"
          id="offcanvasDarkNavbar"
          aria-labelledby="offcanvasDarkNavbarLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">
              Features
            </h5>
            <button
              type="button"
              class="btn-close btn-close-dark"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body flex-lg-row p-4 p-lg-0">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
              <!-- <li class="nav-item mx-lg-3 mx-md-2">
            <a class="nav-link" aria-current="page" href="#testimonials">Testimonials</a>
          </li>  -->      
                <li class="nav-item mx-lg-3 mx-md-2">
            <a class="nav-link" href="#FAQ">FAQs</a>
          </li>
          <li class="nav-item mx-lg-3 mx-md-2">
            <a class="nav-link" href="#contact-us">Contact Us</a>
          </li>
            </ul>
            <div class="d">
              <div class="download">
                <div class="px-md-3">
            <button type="button" class="btn nav_button">Download App</button>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- end of navbar-section -->
  
  <!-- main section -->
    <div>

        <!-- section1 -->
        <div class="section1">
            <div class="container-fluid pt-5">
                <div class="row">
                    <div class="col-md-6 pt-md-5 px-md-5">
                        <div class="pt-md-5 d-flex flex-column align-items-center justify-content-center">
                            <h1 class="pt-md-5 text-white px-md-5"><b>"Workss"</b> the place to get the works and get works done</h1>
                        </div>
                        <div class="d-flex pt-4">
                            <div class="ps-md-5"><img src="@/assets/appstore.png" alt="" style="width: 50; height: 40px;"></div>
                            <div class="px-3"><img src="@/assets/playstore.png" alt="" style="width: 50; height: 40px;"></div>
                        </div>
                    </div>
                    <div class="col-md-6 p-0">
                        <div class="float-md-end">
                            <img src="@/assets/iphone.png" alt="" class="img-fluid" style="max-height: 570px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end of section1 -->

        <!-- section2 -->
        <div class="container-fluid py-4 section2">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <h1 class="text-white text-center sec2">Connect with the world <span class="span-txt">.Workss </span>Awaits!</h1>
                    </div>
                    <!-- <div class="col-md-2"></div>
                    <div class="col-md-2 mb-2 text-center">
                        <div class="px-3"><img src="@/assets/Frame1.png" alt="" style="width: 100; height: 100px;"></div>
                    </div>
                    <div class="col-md-2 mb-2 text-center">
                        <div class="px-3"><img src="@/assets/Frame2.png" alt="" style="width: 100; height: 100px;"></div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- end of section2 -->

         <!-- features -->
        <section class="features">
            <div class="container py-5">
                <h1 class="text-center py-2">Features</h1>
                <p class="text-center pb-2">Discover the features that elevate the functionality and usability of our product.</p>
                <div class="row">
                    <div class="col-lg-4 mb-2 d-flex flex-column align-items-center justify-content-center">
                        <div class="mb-5 c1">
                            <div class="card px-3 py-2" style="width: 270px;">
                                <p class="text-center">Contact experienced professionals directly for works. </p>
                            </div>
                        </div>
                        <div class="mb-5 c2">
                            <div class="card px-3 py-2 float-md-end" style="width: 270px;">
                                <p class="text-center">Create account in simple steps. </p>
                            </div>
                        </div>
                        <div class="mb-1 c3">
                            <div class="card px-3 py-2" style="width: 270px;">
                                <p>Post works and get professionals. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-2 d-flex align-items-center justify-content-center">
                        <img src="@/assets/herosec.jpg" alt="" class="img-fluid mbimg">
                    </div>
                    <div class="col-lg-4 mb-2 d-flex flex-column align-items-center justify-content-center">
                        <div class="mb-5 c4">
                            <div class="card float-md-end px-3 py-2" style="width: 270px;">
                                <p class="text-center">Professionals can showcase their works through videos on the platform.</p>
                            </div>
                        </div>
                        <div class="mb-5 c5">
                            <div class="card px-3 py-2" style="width: 270px;">
                                <p>Available in regional languages. </p>
                            </div>
                        </div>
                        <div class="mb-5 c6">
                            <div class="card float-md-end px-3 py-2" style="width: 270px;">
                                <p class="text-center">Professionals can also post the works in the platform</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end of features -->

        <!-- section3 -->
        <!-- <div class="container pb-5 section3" id="testimonials">
            <h1 class="pt-3 text-center">Real Experiences, Real Results</h1>
            <h6 class="pt-2 pb-5 text-center">aiming to guide users in making informed decisions about downloading and using the application.</h6>
            <div class="row">
                <div class="col-md-4 mb-3 d-flex align-items-center justify-content-center">
                    <div class="card">
                        <div class="card-header p-0">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="pt-3 px-3">
                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/008/310/461/small/user-flat-multicolor-icon-vector.jpg" alt="" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="pt-3">
                                        <h6>Username</h6>
                                        <ul class="list-unstyled d-flex">
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus repellat quisquam earum eligendi eum, dicta praesentium error doloribus consectetur. Perferendis?</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3 d-flex align-items-center justify-content-center">
                    <div class="card">
                        <div class="card-header p-0">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="pt-3 px-3">
                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/008/310/461/small/user-flat-multicolor-icon-vector.jpg" alt="" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="pt-3">
                                        <h6>Username</h6>
                                        <ul class="list-unstyled d-flex">
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus repellat quisquam earum eligendi eum, dicta praesentium error doloribus consectetur. Perferendis?</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3 d-flex align-items-center justify-content-center">
                    <div class="card">
                        <div class="card-header p-0">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="pt-3 px-3">
                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/008/310/461/small/user-flat-multicolor-icon-vector.jpg" alt="" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="pt-3">
                                        <h6>Username</h6>
                                        <ul class="list-unstyled d-flex">
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>    
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus repellat quisquam earum eligendi eum, dicta praesentium error doloribus consectetur. Perferendis?</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3 d-flex align-items-center justify-content-center">
                    <div class="card">
                        <div class="card-header p-0">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="pt-3 px-3">
                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/008/310/461/small/user-flat-multicolor-icon-vector.jpg" alt="" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="pt-3">
                                        <h6>Username</h6>
                                        <ul class="list-unstyled d-flex">
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>    
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus repellat quisquam earum eligendi eum, dicta praesentium error doloribus consectetur. Perferendis?</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3 d-flex align-items-center justify-content-center">
                    <div class="card">
                        <div class="card-header p-0">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="pt-3 px-3">
                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/008/310/461/small/user-flat-multicolor-icon-vector.jpg" alt="" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="pt-3">
                                        <h6>Username</h6>
                                        <ul class="list-unstyled d-flex">
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus repellat quisquam earum eligendi eum, dicta praesentium error doloribus consectetur. Perferendis?</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3 d-flex align-items-center justify-content-center">
                    <div class="card">
                        <div class="card-header p-0">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="pt-3 px-3">
                                        <img src="https://static.vecteezy.com/system/resources/thumbnails/008/310/461/small/user-flat-multicolor-icon-vector.jpg" alt="" class="img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="pt-3">
                                        <h6>Username</h6>
                                        <ul class="list-unstyled d-flex">
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                            <li><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus repellat quisquam earum eligendi eum, dicta praesentium error doloribus consectetur. Perferendis?</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- end of section3 -->

        <!-- section4 -->
        <div class="section4 py-5" id="FAQ">
            <section class="faq-design py-3">
                <div class="container">
                <h1 class="text-center">Real Experiences, Real Results</h1>
                <p class="text-center">Check out the frequently asked questions by our users. </p>

                <div v-for="(faq, index) in faqs" :key="index" class="faq-box py-3">
                    <div
                    class="question d-flex justify-content-between align-items-center text-left"
                    @click="toggleCollapse(index)"
                    :data-bs-toggle="`collapse-${index}`"
                    :data-bs-target="`#faq-${index}`"
                    :aria-expanded="faq.expanded"
                    >
                    <span class="text-left pb-1">{{ faq.question }}</span>
                    <i v-if="!faq.expanded" class="fas fa-plus"></i>
                    <i v-else class="fas fa-minus"></i>
                    </div>
                    <div :id="`faq-${index}`" class="collapse" :class="{ show: faq.expanded }">
                    <p class="text-start text-break" >{{ faq.answer }}</p>
                    </div>
                </div>
                </div>
            </section>
        </div>
        <!-- end of section4 -->

        <!-- section5 -->
        <div class="section5 py-5" id="contact-us">
            <div class="container d-flex align-items-center justify-content-center">
                <div class="card w-100">
                    <div class="row">
                        <div class="col-md-4 details">
                            <div class="detailss py-3">
                                <h6 class="pt-3 px-3 text-white">Contact Details</h6>
                                <div class="row p-3">
                                    <div class="col-1">
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                                    </div>
                                    <div class="col-11">
                                        <p class="text-white">#7, 5th block, manasi nagar,<br> Mysuru, Karnataka, 570019</p>
                                    </div>
                                </div>
                                <div class="row pb-3 px-3">
                                    <div class="col-1">
                                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                                    </div>
                                    <div class="col-11">
                                        <p class="text-white">support@workss.co</p>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-6"></div>
                                    <div class="col-md-6">
                                        <img src="@/assets/mailbox.png" alt="" class="img-fluid">
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-8 detailz">
                            <h3 class="px-3 py-1 mt-3">Have Questions? Contact Us Here</h3>
                            <div class="px-3">
                                <div class="form__group field">
                                    <input type="input" class="form__field" placeholder="Name" name="name" id='name' required />
                                    <label for="name" class="form__label">Enter your name</label>
                                </div>
                            </div>
                            <div class="px-3">
                                <div class="form__group field">
                                    <input type="input" class="form__field" placeholder="Email" name="email" id='email' required />
                                    <label for="email" class="form__label">Enter your email address</label>
                                </div>
                            </div>
                            <div class="px-3">
                                <div class="form__group field">
                                    <input type="input" class="form__field" placeholder="Mobile Number" name="mobile" id='mobile' required />
                                    <label for="mobile" class="form__label">Enter your mobile number</label>
                                </div>
                            </div>
                            <div class="px-3">
                                <div class="form__group field">
                                    <textarea class="form__field" placeholder="Type Here..." name="msg" id='msg' required />
                                    <label for="msg" class="form__label">Write a query here</label>
                                </div>
                            </div>
                            <div class="float-end px-4 px-md-5 py-2">
                                <div class="px-3 px-md-3">
                                    <button type="button" class="btn btn-primary">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end of section5 -->

        <!-- footer -->
        <footer class="container-fluid  py-3 footer">
            <div class="container py-3">
            <div class="row">
                <div class="col-md-6">
                <div class="pb-0">
                    <h2 class="py-3">Get <span><b>.Workss</b></span> Instantly</h2>
                <div class="d-flex mb-4">
                    <div><img src="@/assets/appstore.png" alt="AppStore" style="width: 50; height: 40px;"></div>
                <div class="px-3"><img src="@/assets/playstore.png" alt="playStore" style="width: 50; height: 40px;"></div>
                    </div>
                </div>
                </div>
                <div class="col-md-3">
                    <div class="mb-3">
                        <h6 class="mb-2" style="color: gold">Policies</h6>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a href="/privacy-policy" class="mb-1" style="text-decoration: none; color: #fff;">Privacy Policy</a></li>
                            <li class="mb-2"><a href="/terms&conditions" class="mb-1" style="text-decoration: none; color: #fff;">Terms &amp; Conditions</a></li>
                        </ul>
                        
                    </div></div>
                <div class="col-md-3">
                <h6 style="color: gold" class="mb-2">Social Links</h6>
                <ul class="list-unstyled d-flex">
                    <li class="pe-3"><a href="https://www.facebook.com/workssco?mibextid=ZbWKwL"><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg></a></li>
                    <li class="px-3"><a href="https://www.instagram.com/invites/contact/?i=bbyujf289fcj&utm_content=syceon9"><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a></li>
                    <li class="px-3"><a href="#"><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg></a></li>
                    <li class="px-3"><a href="https://x.com/workssco?t=Knw_hd0oAUt3fqU-PniqDw&s=09"><svg class="icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></a></li>
                </ul>
                </div>
            </div>
            </div>
            
        </footer>
        <div class="container-fluid" style="background-color: black;">
            <div class="row">
                <div class="col">
                <div class="text-white">
                    <p class="text-center pt-2">&copy;2023 Jobsujunction Innovation LLP. All rights Reserved</p> 
                </div>
                </div>
                
            </div>
        </div>
        <!-- end of footer -->

    </div>
  <!-- end of main section -->
</template>

<script>
export default {
    name: 'App',
    data() {
    return {
      faqs: [
        {
          question: "1. Do professionals need to pay for the any service? ",
          answer: "No. Workss app completely free for professionals.",
          expanded: false,
        },
        {
          question: "2. Is my personal information secure on the app?",
          answer: "Yes, in workss apps we prioritize user privacy and we employ security measures to protect your personal information.",
          expanded: false,
        },
        {
          question: "3. How can I contact support if I encounter issues with the app?",
          answer: "Look for a 'Contact Us' or 'help and support' section in the app account section. Write us the issues you are facing.",
          expanded: false,
        },
        {
          question: "4. Is the app available on multiple platforms (iOS, Android)?",
          answer: "yes. Workss app available for both iOS and android users.",
          expanded: false,
        },
        {
          question: "5. Can employers contact the professionals on the platform directly?",
          answer: "No, employers must take membership plan we offer can contact professionals directly. The employer who don't take membership, they can contact the professionals who show the interest on works posted by employers.",
          expanded: false,
        },
        {
          question: "6. What is the  Advantages of taking membership plan?",
          answer: "This membership enhances networking opportunities and connections within our platform.",
          expanded: false,
        },
        {
          question: "7. How do I create an account on the workss app?",
          answer: "To create an account, simply download the app, open it, and follow the registration process, providing the required information.",
          expanded: false,
        }
        // Add more FAQ items as needed
      ],
    };
  },
  methods: {
    toggleCollapse(index) {
      this.faqs[index].expanded = !this.faqs[index].expanded;
    },
  },
}
</script>

<style>
    @media only screen and (min-width: 770px) {
        .mbimg {
            max-width: 800px !important;
        }
    }
    .navbar{
        background-color: #1F75FE;
    }
    .navbar-brand {
        color: #ffffff;
    }
    .navbar .navbar-nav a {
        color: #ffffff !important;
    }
    .navbar .nav_button {
        background-color: #ffffff;
        color: #1F75FE;
    }
    @media (max-width: 767.98px) {
        .d {
            padding-top: 14px;
        }
    }
    .section1 {
        background-image: url("~@/assets/home.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #1F75FE;
    }
    .section2 {
        background-color: #000;
    }
    @media only screen and (max-width: 770px) {
        .section2 .sec2 {
            text-align:center;
        }
    }
    .span-txt {
        color: #1F75FE;
    }
    .section3 .card-header {
        background-color: #ffffff;
        border: none;
    }
    .section3 .icon path {
        fill: gold;
    }
    .section3 .card {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
    .section4 {
        background-color: rgb(6, 6, 57);
        color: #ffffff;
    }
    .section5 .card .details {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }
    .section5 .card .detailss {
        /* background-color: #1F75FE; */
        background-image: url("~@/assets/mail.png");
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        height: 100%;
    }
    .section5 .card .detailz {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
    .section5 .icon path {
        fill: #fff;
    }
    .section5 .btn-primary {
        background-color: #1F75FE;
        color: #ffffff;
    }
    .section6 {
        background-color: #1F75FE;
    }
    .section6 .icon path {
        fill: #fff;
    }
    .footer {
        background-color: #1F75FE;
        color: #ffffff;
    }
    .footer .icon path{
        fill: #ffffff;
    }

    @media only screen and (min-width: 1200px)  {

        .c1, .c3 {
            padding-left: 30px;
        }
        .c2 {
            padding-left: 130px;
        }

        .c4, .c6 {
            padding-right: 30px;
        }
        .c5 {
            padding-right: 130px;
        }
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px)  {
        .c1, .c3 {
            padding-left: 0px;
        }
        .c2 {
            padding-left: 50px;
        }

        .c4, .c6 {
            padding-right: 0px;
        }
        .c5 {
            padding-right: 50px;
        }
    }
    
    .faq-design .question {
  font-weight: bold;
  cursor: pointer;
}
.faq-design .faq-box:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 94%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #c9c5c5;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1.0rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #8b8383;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 1px;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #8b8383;
}

/* reset input */
.form__field:required, .form__field:invalid {
  box-shadow: none;
}

/* features card*/
.card {
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navbar-toggler:focus {
    color: #fff !important;
}

.custom-toggler.navbar-toggler {
    border-color: #ffffff !important;
}

.custom-toggler.navbar-toggler:hover {
    border-color: none !important;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.nav_button:hover {
    background-color: #ffffff !important;
    color: #1F75FE !important;
}
</style>