<template>
  <h3 class="py-3 text-center text-3xl font-bold dark:text-dark">
    Professional Details
  </h3>
  <div class="d-flex align-items-center justify-content-center">
    <div class="w-full max-w-xxl px-2">
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="row">
          <div class="col-md-6 left">
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="name"
                  >
                    Name :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.name"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="mobile"
                  >
                    Mobile :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.mobile"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="usertype"
                  >
                    UserType :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.userType"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="gender"
                  >
                    Gender :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.userGender"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="aadhar"
                  >
                    Aadhar Number :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.aadharNo"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="payment"
                  >
                    Payment :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.paid"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="knownLang"
                  >
                    Known Languages :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.userKnownLanguages"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <!-- <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="isProfessional">
                    is Professional? :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.isProfessional"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div> -->
          </div>
          <div class="col-md-6 right">
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="email"
                  >
                    Email :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.email"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="city"
                  >
                    City :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.userCity"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="exp"
                  >
                    year Of Experience :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.yearOfExp"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="userId"
                  >
                    User ID :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.userId"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="joined"
                  >
                    Joined At :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.created"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="expDate"
                  >
                    Expiry Date :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.expiryDate"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2 text-start"
                    for="selectedlang"
                  >
                    Language Selected :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.languageSelected"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <!-- <div class="div mb-2">
              <div class="row">
                <div class="col-md-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2 text-start" for="isEmployer">
                    is Employer? :
                  </label>
                </div>
                <div class="col-md-8">
                  <input
                    v-model="list.isEmployer"
                    class="w-full text-gray-700 bg-transparent"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="flex items-center justify-center pt-4">
          <button
            v-on:click="onDeleteProfessionals(list)"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            style="background: #0167ff"
          >
            Delete
          </button>
        </div>
      </form>


      <div>
        <h6 class="py-3 text-center text-2xl font-bold dark:text-dark">
          Bench
        </h6>
        <div class="container">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(b, index) in list.bench"
              :key="index"
            >
              <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div class="row">
                  <video controls :src="`https://workss.co/works_api/bench/${b.benchUrl}`"></video>
                </div>
                <div class="flex items-center justify-center pt-4">
          <button
            v-on:click="onDeleteBench(b)"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            style="background: #0167ff"
          >
            Delete
          </button>
        </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h6 class="py-3 text-center text-2xl font-bold dark:text-dark">
          Works
        </h6>
        <div class="container">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(work, index) in list.works"
              :key="index"
            >
              <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div class="row">
                  <div>
                    <div class="mb-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            class="block text-gray-700 text-sm font-bold mb-2 text-start"
                            for="type"
                          >
                            Type :
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input
                            v-model="work.type"
                            class="w-full text-gray-700 bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            class="block text-gray-700 text-sm font-bold mb-2 text-start"
                            for="place"
                          >
                            Work Place :
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input
                            v-model="work.workPlace"
                            class="w-full text-gray-700 bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            class="block text-gray-700 text-sm font-bold mb-2 text-start"
                            for="langs"
                          >
                            Known Languages :
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input
                            v-model="work.knownLanguages"
                            class="w-full text-gray-700 bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            class="block text-gray-700 text-sm font-bold mb-2 text-start"
                            for="explevel"
                          >
                            Experience Level :
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input
                            v-model="work.experienceLevel"
                            class="w-full text-gray-700 bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="mb-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            class="block text-gray-700 text-sm font-bold mb-2 text-start"
                            for="location"
                          >
                            Location :
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input
                            v-model="work.location"
                            class="w-full text-gray-700 bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            class="block text-gray-700 text-sm font-bold mb-2 text-start"
                            for="gen"
                          >
                            Gernder :
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input
                            v-model="work.gender"
                            class="w-full text-gray-700 bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            class="block text-gray-700 text-sm font-bold mb-2 text-start"
                            for="createdAt"
                          >
                            Created At :
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input
                            v-model="work.created"
                            class="w-full text-gray-700 bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-2">
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            class="block text-gray-700 text-sm font-bold mb-2 text-start"
                            for="cancall"
                          >
                            Can Call? :
                          </label>
                        </div>
                        <div class="col-md-8">
                          <input
                            v-model="work.canCall"
                            class="w-full text-gray-700 bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="pt-3">
                      <div class="text-center">
                        <div class="flex items-center justify-center">
        <button
          v-on:click="onDeleteWorks(work)"
          class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          style="background: #0167ff"
        >
          Delete
        </button>
      </div>
                      </div>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "team_details",
  data() {
    return {
      list: {
        employerId: "",
        name: "",
        email: "",
        mobile: "",
      },
    };
  },
  methods: {
    onDeleteWorks(work) {
      console.log(work.wrkid);
      console.log(`https://workss.co/works_api/deleteWork.php?wrkid=${work.wrkid}`)
      axios
        .get(
          `https://workss.co/works_api/deleteWork.php?wrkid=${work.wrkid}`
        )
        .then((response) => {
          // console.log(response)
          this.$router.go(this.$router.currentRoute);
        }).catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
    onDeleteBench(b) {
      axios
        .get(
          `https://workss.co/works_api/adminDeleteBench.php?id=${b.id}`
        )
        .then((response) => {
          console.log(response)
          // this.$router.go(this.$router.currentRoute);
        }).catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });

    },
    async onDeleteProfessionals(list) {
      // this.id = this.$route.params.userId;
      // let params = new URLSearchParams();
      // params.append("userId", this.id);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
        .then((result) => {
          if (result.isConfirmed) {

            console.log(`https://workss.co/works_api/adminDeleteUser.php?userId=${list.userId}`);
            axios
              .post(`https://workss.co/works_api/adminDeleteUser.php?userId=${list.userId}`)
              .then((response) => {
                console.log(response);
                Swal.fire("Deleted!", "Record has been deleted.", "success");
                // this.$router.push({ path: "/employers" });
              })
              
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
  },
  async mounted() {
    this.id = this.$route.params.userId;
    let params = new URLSearchParams();
    params.append("userId", this.id);
    const result = await axios
      .post("https://workss.co/works_api/getUserAllData.php", params)
      .then((response) => {
        console.log(response);
        // console.log(response.data[0]);
        this.list = response.data;
      })
      .catch((error) => {
        if (error.response) {
          console.log(
            "Server responded with status code:",
            error.response.status
          );
          console.log("Response data:", error.response.data);
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error creating request:", error.message);
        }
      });
  },
};
</script>

<style scoped>
video {
    height: 300px;
    width: 100vw;
    object-fit: fill;
}
</style>