<template>

    <h3 class="pt-5 text-center text-3xl font-bold dark:text-dark">FeedBack</h3>

    <div id="recipients" class="pb-8 mt-6 lg:mt-0">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 ">
            <table class="pt-3 table w-full text-sm text-left text-gray-500 dark:text-gray-400" id="datatable">
                <thead class="table-dark text-xs text-white uppercase bg-gray-50 dark:bg-gray-700 dark:text-white">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Email
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Mobile
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Query
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Status
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Enquiry Date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 text-gray-800" v-for="list in lists" :key="list.id">
                        <td class="px-6 py-4">
                            {{ list.name }}
                        </td>
                        <td class="px-6 py-4">
                            {{ list.email }}
                        </td>
                        <td class="px-6 py-4">
                            {{ list.mobile }}
                        </td>
                        <td class="px-6 py-4">
                            {{ list.message }}
                        </td>
                        <td class="px-6 py-4">
                            {{ list.status }}
                        </td>
                        <td class="px-6 py-4">
                            {{ list.created }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import $ from 'jquery';
    import "jquery/dist/jquery.min.js";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    export default {
        name: "App",
        data() {
            return {
                lists: []
            }
        },
        mounted() {
            axios.get('https://workss.co/works_api/getFeedback.php')
            .then((response) => {
                console.log(response);
                this.lists = response.data,
                setTimeout(() => {
                    $("#datatable")
                    .DataTable({
                        responsive: true,
                    })
                    .columns.adjust()
                });
            })
            .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
        }
    }

</script>