<template>
  <div
    class="d-flex align-items-center justify-content-center vh-100 bg-gray-50 login"
  >
    <div class="w-full max-w-sm px-2">
      <a
        href="#"
        class="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
      >
        <img src="@/assets/Works.png" class="img-fluid" alt="" />
      </a>
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="email"
          >
            Email
          </label>
          <input
            v-model="email"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="Email"
          />
        </div>
        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2 text-start"
            for="password"
          >
            Password
          </label>
          <input
            v-model="password"
            class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
          />
        </div>
        <div class="flex items-center justify-center">
          <button
            v-on:click="login()"
            class="w-full bg-blue-500 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
    }
  },
  methods: {
    async login() {
      if(this.email == 'shreekanth@works.in' && this.password == '1234') {
        this.$storage.setStorageSync("email", this.email);
          this.$router.push({ path: "/dashboard" });
      }
      //     this.$router.push({ path: "/dashboard" });
      // axios
      //   .post("https://workss.co/works_api/adminLogin.php", {
      //     email: this.email,
      //     password: this.password,
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .then(() => {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Login Success!",
      //       showConfirmButton: false,
      //       timer: 2000,
      //     })
      //   })
      //   .catch((error) => {
      //     if (error.response) {
      //       console.log(
      //         "Server responded with status code:",
      //         error.response.status
      //       );
      //       if(error.response.status == 400){
      //         Swal.fire({
      //           icon: "error",
      //           title: "User Not Found!",
      //           showConfirmButton: false,
      //           timer: 2000,
      //         })
      //       } else {
      //         Swal.fire({
      //           icon: "error",
      //           title: "Invalid Credentials!",
      //           showConfirmButton: false,
      //           timer: 2000,
      //         })
      //       }
      //       console.log("Response data:", error.response.data)
      //     } else if (error.request) {
      //       console.log("No response received:", error.request);
      //     } else {
      //       console.log("Error creating request:", error.message);
      //     }
      //   });
    },
  },
};
</script>

<style scoped>
.login {
  background: #1F75FE;
}
</style>
