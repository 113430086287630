import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'

import EmployerList from '@/components/EmployerComponents/EmployerList.vue'
import EmployerDetails from '@/components/EmployerComponents/EmployerDetails.vue'

import getProfessionals from '@/components/ProfessionalComponents/getProfessionals.vue'
import getProfessionalsDetails from '@/components/ProfessionalComponents/getProfessionalsDetails.vue'

import feedbacklist from '@/components/FeedbackComponents/feedbacklist.vue'

import termsconditions from '@/components/termsconditions.vue'
import privacypolicy from '@/components/privacypolicy.vue'
import Works from '@/components/Works.vue'


const routes = [
  {
    name: 'Master',
    path: '/home',
    redirect: '/dashboard',
    component: () => import('../pages/layout/master.vue'),
    children: [
      {
        name: 'dashbaord',
        path: '/dashboard',
        component: () => import('../pages/dashboard.vue'),
      },
      {
        name: 'employers',
        path: '/employers',
        component: EmployerList
      },
      {
        name: 'employer_details',
        path: '/employer_details/:userId',
        component: EmployerDetails
      },
      {
        name: 'professionals',
        path: '/professionals',
        component: getProfessionals
      },
      {
        name: 'professionals_details',
        path: '/professionals_details/:userId',
        component: getProfessionalsDetails
      },
      {
        name: 'feedbacklist',
        path: '/feedback',
        component: feedbacklist
      },
    ]
  },
  {
    path: '/admin',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'Works',
    component: Works
  },
  {
    path: '/terms&conditions',
    name: 'termsconditions',
    component: termsconditions
  },
  {
    path: '/privacy-policy',
    name: 'privacypolicy',
    component: privacypolicy
  }
]

const router = createRouter({
  base: process.env.VUE_APP_PUBLICPATH,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
